import React, { useState, useCallback } from "react"
import * as DiscogsUtils from "../helpers/discogs-utils"
import * as Cache from "../helpers/cache"
import ItemsList from "../components/items-list"
import { ArtistLabelList } from "../helpers/discogs-types"
import { graphql, useStaticQuery } from "gatsby"
import Page from "../components/page"
import { Helmet } from "react-helmet"

const getLabels = async (cursor: string) => {
  return DiscogsUtils.getLabelItems(cursor)
}

const setLabelsCache = (labels: ArtistLabelList) => {
  const CACHE_KEY = "labels"

  Cache.setItem(CACHE_KEY, JSON.stringify(labels))
}

const getLabelsCache = (): ArtistLabelList | null => {
  const CACHE_KEY = "labels"

  const json = Cache.getItem(CACHE_KEY)
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const CACHE_KEY_CURSOR = "labels-cursor"

export default function Labels() {
  const buildData = useStaticQuery(graphql`
    query {
      labels {
        nextPage
        items {
          id
          name
          count
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let initLabels = buildData.labels.items
  let initNextPage = buildData.labels.nextPage

  const [labels, setLabels] = useState<ArtistLabelList>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheLabels = getLabelsCache()
      if (cacheLabels !== null) {
        return cacheLabels
      }
    }
    return initLabels
  })
  const [nextPage, setNextPage] = useState<string>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheCursor = Cache.getItem(CACHE_KEY_CURSOR)
      if (cacheCursor !== null) {
        return cacheCursor
      }
    }
    return initNextPage
  })
  const [loadingPage, setLoadingPage] = useState<boolean>(false)

  const siteTitle = buildData.site.siteMetadata.title

  const loadMoreHandler = useCallback(async () => {
    setLoadingPage(true)
    const response = await getLabels(nextPage)
    setLabels(prev => {
      const items = [...prev, ...response.items]
      setLabelsCache(items)
      return items
    })
    setNextPage(response.cursor)
    Cache.setItem(CACHE_KEY_CURSOR, response.cursor)
    setLoadingPage(false)
  }, [nextPage])

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${siteTitle} - Labels`}</title>
        <meta
          name="description"
          content="A list of record labels that make up my records"
        />
      </Helmet>
      <Page navTitle="Labels">
        <ItemsList
          type="labels"
          items={labels}
          nextPage={nextPage}
          loadingPage={loadingPage}
          loadMoreHandler={loadMoreHandler}
        />
      </Page>
    </React.Fragment>
  )
}
